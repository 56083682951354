import { render, staticRenderFns } from "./ConfigAlertVictorOps.vue?vue&type=template&id=6381abe8&scoped=true"
import script from "./ConfigAlertVictorOps.vue?vue&type=script&lang=js"
export * from "./ConfigAlertVictorOps.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6381abe8",
  null
  
)

export default component.exports