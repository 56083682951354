<template>
  <table>
    <slot />
  </table>
</template>

<script>
export default {};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
</style>
