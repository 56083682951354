var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.configLoaded)?_c('div',[(_vm.template.name)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRename),expression:"showRename"}]},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":6}},[_c('el-input',{ref:"rename",attrs:{"size":"large","autofocus":"","autoselect":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.rename.apply(null, arguments)}},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}})],1),_c('el-col',{attrs:{"span":18}},[_c('el-button',{attrs:{"size":"large","type":"primary"},on:{"click":_vm.rename}},[_vm._v(" Save ")]),_c('el-button',{attrs:{"size":"large"},on:{"click":function($event){_vm.showRename = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('h1',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRename),expression:"!showRename"}]},[_vm._v(" "+_vm._s(_vm.template.name)+" ")]),_c('el-row',[_c('router-link',{attrs:{"to":{
          name: 'ruleconfigbuilder',
          params: { action: 'add' },
          query: { prefill: _vm.id },
        }}},[_c('el-button',{attrs:{"icon":"el-icon-plus","plain":"","type":"primary"}},[_vm._v(" Create rule from template ")])],1),_c('router-link',{attrs:{"to":{
          name: 'templateconfigeditor',
          params: { action: 'edit', path: _vm.id },
        }}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit","plain":""}},[_vm._v(" Edit ")])],1),_c('el-button',{attrs:{"plain":"","type":"info"},on:{"click":_vm.showRenameInput}},[_vm._v(" Rename ")]),_c('el-button',{attrs:{"plain":"","type":"info"},on:{"click":_vm.duplicate}},[_vm._v(" Duplicate ")]),_c('el-button',{attrs:{"plain":"","type":"info"},on:{"click":_vm.showMoveDialog}},[_vm._v(" Move ")]),_c('el-button',{attrs:{"icon":"el-icon-delete","plain":"","type":"danger"},on:{"click":_vm.showDeleteConfirm}},[_vm._v(" Delete... ")])],1),_c('el-dialog',{attrs:{"visible":_vm.moveVisible,"title":"Move","width":"40%"},on:{"update:visible":function($event){_vm.moveVisible=$event},"close":function($event){_vm.moveVisible = false}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span',[_c('el-button',{on:{"click":function($event){_vm.moveVisible = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.move}},[_vm._v("Move template")])],1)]},proxy:true}],null,false,482109152)},[_c('div',[_c('FolderTree',{attrs:{"type":"templates"},model:{value:(_vm.moveDest),callback:function ($$v) {_vm.moveDest=$$v},expression:"moveDest"}})],1)]),(_vm.configLoaded && _vm.elastalertConfigLoaded)?[_c('ConfigSettings',{attrs:{"view-only":true,"type":"template"}}),_c('ConfigCondition',{staticClass:"condition-view m-n-med m-s-xl"}),_c('ConfigAlert',{attrs:{"view-only":true}})]:_vm._e()],2):_c('div',[_c('el-alert',{attrs:{"closable":false,"title":"Template not found, it may have been moved or deleted.","type":"error","show-icon":""}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }