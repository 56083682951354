import { render, staticRenderFns } from "./ConfigAlertZabbix.vue?vue&type=template&id=5a7a6806&scoped=true"
import script from "./ConfigAlertZabbix.vue?vue&type=script&lang=js"
export * from "./ConfigAlertZabbix.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7a6806",
  null
  
)

export default component.exports