import { render, staticRenderFns } from "./ConfigAlertMattermost.vue?vue&type=template&id=6fd84560&scoped=true"
import script from "./ConfigAlertMattermost.vue?vue&type=script&lang=js"
export * from "./ConfigAlertMattermost.vue?vue&type=script&lang=js"
import style0 from "./ConfigAlertMattermost.vue?vue&type=style&index=0&id=6fd84560&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd84560",
  null
  
)

export default component.exports